import React from "react";
import { Mutation } from "@apollo/react-components";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { Typography, Card, CardContent, Grid, Button } from "@material-ui/core";

import { Save } from "@material-ui/icons";

import { GET_PRODUCTS, ADD_PRODUCT } from "./../../../helpers/queries/Product";

import ProductForm from "./ProductForm";

import { openSnackbar } from "./../../reusable/Notifier";
import { type } from "os";

export default function AddProduct({ history }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Typography component="h1" variant="h4" gutterBottom>
          New Product
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Mutation
          mutation={ADD_PRODUCT}
          refetchQueries={[
            {
              query: GET_PRODUCTS,
            },
          ]}
          onError={(error) => {
            openSnackbar({
              message: error.message,
              type: "error",
            });
          }}
        >
          {(addProduct) => (
            <Form
              onSubmit={(values) => {
                addProduct({
                  variables: {
                    colours: JSON.stringify(values.productColours),
                    name: values.productName,
                    sizes: JSON.stringify(values.productSizes),
                    type: values.productType,
                  },
                }).then(({ data }) => {
                  if (data.addProduct.error) {
                    openSnackbar({
                      message: data.addProduct.error.message,
                      type: "error",
                    });
                  } else {
                    openSnackbar({
                      message: "Product added successfully",
                      type: "success",
                    });
                    history.push("/admin-settings/products");
                  }
                });
              }}
              mutators={{
                ...arrayMutators,
              }}
              validate={(values) => {
                const errors = { productColours: [], productSizes: [] };

                const requiredFields = ["productName", "productType"];

                requiredFields.forEach((requiredField) => {
                  if (!values[requiredField]) {
                    errors[requiredField] = "Required";
                  }
                });

                let sizeCodeRequired = true;
                let colourCodeRequired = true;

                values.productSizes.forEach((size, index) => {
                  if (size.sizeCode) {
                    colourCodeRequired = false;
                  }
                });

                //check if each colour has required
                values.productColours.forEach((colour, index) => {
                  errors.productColours.push({});
                  if (!colour.colourName) {
                    errors.productColours[index].colourName = "Required";
                  }

                  if (colour.colourCode) {
                    sizeCodeRequired = false;
                  }

                  if (colourCodeRequired && !colour.colourCode) {
                    errors.productColours[index].colourCode =
                      "Either colour code or size code is required";
                  }
                });

                //checks size
                values.productSizes.forEach((size, index) => {
                  errors.productSizes.push({});
                  if (!size.sizeDimension) {
                    errors.productSizes[index].sizeDimension = "Required";
                  }
                  if (!size.sizePrice) {
                    errors.productSizes[index].sizePrice = "Required";
                  }
                  if (sizeCodeRequired && !size.sizeCode) {
                    errors.productSizes[index].sizeCode =
                      "Either colour code or size code is required";
                  }
                });

                return errors;
              }}
              initialValues={{
                productName: "",
                productColours: [{ colourName: "", colourCode: "" }],
                productSizes: [
                  {
                    sizeDimension: "",
                    sizeCode: "",
                    sizePrice: "",
                    sizeDelivery: "",
                    sizeFitting: "",
                  },
                ],
              }}
              render={({ handleSubmit, pristine, invalid, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ProductForm />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={pristine || invalid || submitting}
                            type="submit"
                          >
                            <Save style={{ marginRight: "10px" }} />
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </form>
              )}
            />
          )}
        </Mutation>
      </Grid>
    </Grid>
  );
}
